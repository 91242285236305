enifed('@ember/map/lib/utils', ['exports', '@ember/deprecated-features'], function (exports, _deprecatedFeatures) {
  'use strict';

  exports.copyNull = exports.copyMap = undefined;


  let copyNull, copyMap;

  if (_deprecatedFeatures.MAP || _deprecatedFeatures.ORDERED_SET) {
    exports.copyNull = copyNull = function copyNull(obj) {
      let output = Object.create(null);

      for (let prop in obj) {
        // hasOwnPropery is not needed because obj is Object.create(null);
        output[prop] = obj[prop];
      }

      return output;
    };

    exports.copyMap = copyMap = function copyMap(original, newObject) {
      let keys = original._keys.copy();
      let values = copyNull(original._values);

      newObject._keys = keys;
      newObject._values = values;
      newObject.size = original.size;

      return newObject;
    };
  }

  exports.copyMap = copyMap;
  exports.copyNull = copyNull;
});