enifed('@ember/-internals/views/lib/mixins/child_views_support', ['exports', '@ember/-internals/metal', '@ember/-internals/views/lib/system/utils'], function (exports, _metal, _utils) {
  'use strict';

  exports.default = _metal.Mixin.create({
    /**
      Array of child views. You should never edit this array directly.
       @property childViews
      @type Array
      @default []
      @private
    */
    childViews: (0, _metal.descriptor)({
      configurable: false,
      enumerable: false,
      get() {
        return (0, _utils.getChildViews)(this);
      }
    }),

    appendChild(view) {
      (0, _utils.addChildView)(this, view);
    }
  });
});