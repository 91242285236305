enifed("@ember/debug/lib/handlers", ["exports"], function (exports) {
    "use strict";

    let HANDLERS = exports.HANDLERS = {};
    let registerHandler = () => {};
    let invoke = () => {};
    if (true /* DEBUG */) {
            exports.registerHandler = registerHandler = function registerHandler(type, callback) {
                let nextHandler = HANDLERS[type] || (() => {});
                HANDLERS[type] = (message, options) => {
                    callback(message, options, nextHandler);
                };
            };
            exports.invoke = invoke = function invoke(type, message, test, options) {
                if (test) {
                    return;
                }
                let handlerForType = HANDLERS[type];
                if (handlerForType) {
                    handlerForType(message, options);
                }
            };
        }
    exports.registerHandler = registerHandler;
    exports.invoke = invoke;
});