define("ember-link-action/initializers/allow-link-action", ["exports", "ember-link-action/mixins/link-action"], function (_exports, _linkAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize() {
    Ember.LinkComponent.reopen(_linkAction.default);
  }

  var _default = {
    name: 'allow-link-action',
    initialize
  };
  _exports.default = _default;
});