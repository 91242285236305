enifed('@ember/deprecated-features/index', ['exports'], function (exports) {
  'use strict';

  const SEND_ACTION = exports.SEND_ACTION = !!'3.4.0';
  const EMBER_EXTEND_PROTOTYPES = exports.EMBER_EXTEND_PROTOTYPES = !!'3.2.0-beta.5';
  const RUN_SYNC = exports.RUN_SYNC = !!'3.0.0-beta.4';
  const LOGGER = exports.LOGGER = !!'3.2.0-beta.1';
  const POSITIONAL_PARAM_CONFLICT = exports.POSITIONAL_PARAM_CONFLICT = !!'3.1.0-beta.1';
  const ARRAY_AT_EACH = exports.ARRAY_AT_EACH = !!'3.1.0-beta.1';
  const TARGET_OBJECT = exports.TARGET_OBJECT = !!'2.18.0-beta.1';
  const MAP = exports.MAP = !!'3.3.0-beta.1';
  const ORDERED_SET = exports.ORDERED_SET = !!'3.3.0-beta.1';
  const MERGE = exports.MERGE = !!'3.6.0-beta.1';
  const HANDLER_INFOS = exports.HANDLER_INFOS = !!'3.9.0';
  const ROUTER_EVENTS = exports.ROUTER_EVENTS = !!'3.9.0';
  const TRANSITION_STATE = exports.TRANSITION_STATE = !!'3.9.0';
});