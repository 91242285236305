enifed('@ember/canary-features/index', ['exports', '@ember/-internals/environment', '@ember/polyfills'], function (exports, _environment, _polyfills) {
    'use strict';

    exports.GLIMMER_MODIFIER_MANAGER = exports.EMBER_GLIMMER_ANGLE_BRACKET_INVOCATION = exports.GLIMMER_CUSTOM_COMPONENT_MANAGER = exports.EMBER_METAL_TRACKED_PROPERTIES = exports.EMBER_MODULE_UNIFICATION = exports.EMBER_ENGINES_MOUNT_PARAMS = exports.EMBER_ROUTING_ROUTER_SERVICE = exports.EMBER_GLIMMER_NAMED_ARGUMENTS = exports.EMBER_IMPROVED_INSTRUMENTATION = exports.EMBER_LIBRARIES_ISREGISTERED = exports.FEATURES = exports.DEFAULT_FEATURES = undefined;
    exports.isEnabled = isEnabled;

    /**
      Set `EmberENV.FEATURES` in your application's `config/environment.js` file
      to enable canary features in your application.
    
      See the [feature flag guide](https://guides.emberjs.com/release/configuring-ember/feature-flags/)
      for more details.
    
      @module @ember/canary-features
      @public
    */
    const DEFAULT_FEATURES = exports.DEFAULT_FEATURES = {
        EMBER_LIBRARIES_ISREGISTERED: false,
        EMBER_IMPROVED_INSTRUMENTATION: false,
        EMBER_GLIMMER_NAMED_ARGUMENTS: true,
        EMBER_ROUTING_ROUTER_SERVICE: true,
        EMBER_ENGINES_MOUNT_PARAMS: true,
        EMBER_MODULE_UNIFICATION: false,
        GLIMMER_CUSTOM_COMPONENT_MANAGER: true,
        GLIMMER_MODIFIER_MANAGER: false,
        EMBER_METAL_TRACKED_PROPERTIES: false,
        EMBER_GLIMMER_ANGLE_BRACKET_INVOCATION: true
    };
    /**
      The hash of enabled Canary features. Add to this, any canary features
      before creating your application.
    
      @class FEATURES
      @static
      @since 1.1.0
      @public
    */
    const FEATURES = exports.FEATURES = (0, _polyfills.assign)(DEFAULT_FEATURES, _environment.ENV.FEATURES);
    /**
      Determine whether the specified `feature` is enabled. Used by Ember's
      build tools to exclude experimental features from beta/stable builds.
    
      You can define the following configuration options:
    
      * `EmberENV.ENABLE_OPTIONAL_FEATURES` - enable any features that have not been explicitly
        enabled/disabled.
    
      @method isEnabled
      @param {String} feature The feature to check
      @return {Boolean}
      @since 1.1.0
      @public
    */
    function isEnabled(feature) {
        let featureValue = FEATURES[feature];
        if (featureValue === true || featureValue === false) {
            return featureValue;
        } else if (_environment.ENV.ENABLE_OPTIONAL_FEATURES) {
            return true;
        } else {
            return false;
        }
    }
    function featureValue(value) {
        if (_environment.ENV.ENABLE_OPTIONAL_FEATURES && value === null) {
            return true;
        }
        return value;
    }
    const EMBER_LIBRARIES_ISREGISTERED = exports.EMBER_LIBRARIES_ISREGISTERED = featureValue(FEATURES.EMBER_LIBRARIES_ISREGISTERED);
    const EMBER_IMPROVED_INSTRUMENTATION = exports.EMBER_IMPROVED_INSTRUMENTATION = featureValue(FEATURES.EMBER_IMPROVED_INSTRUMENTATION);
    const EMBER_GLIMMER_NAMED_ARGUMENTS = exports.EMBER_GLIMMER_NAMED_ARGUMENTS = featureValue(FEATURES.EMBER_GLIMMER_NAMED_ARGUMENTS);
    const EMBER_ROUTING_ROUTER_SERVICE = exports.EMBER_ROUTING_ROUTER_SERVICE = featureValue(FEATURES.EMBER_ROUTING_ROUTER_SERVICE);
    const EMBER_ENGINES_MOUNT_PARAMS = exports.EMBER_ENGINES_MOUNT_PARAMS = featureValue(FEATURES.EMBER_ENGINES_MOUNT_PARAMS);
    const EMBER_MODULE_UNIFICATION = exports.EMBER_MODULE_UNIFICATION = featureValue(FEATURES.EMBER_MODULE_UNIFICATION);
    const EMBER_METAL_TRACKED_PROPERTIES = exports.EMBER_METAL_TRACKED_PROPERTIES = featureValue(FEATURES.EMBER_METAL_TRACKED_PROPERTIES);
    const GLIMMER_CUSTOM_COMPONENT_MANAGER = exports.GLIMMER_CUSTOM_COMPONENT_MANAGER = featureValue(FEATURES.GLIMMER_CUSTOM_COMPONENT_MANAGER);
    const EMBER_GLIMMER_ANGLE_BRACKET_INVOCATION = exports.EMBER_GLIMMER_ANGLE_BRACKET_INVOCATION = featureValue(FEATURES.EMBER_GLIMMER_ANGLE_BRACKET_INVOCATION);
    const GLIMMER_MODIFIER_MANAGER = exports.GLIMMER_MODIFIER_MANAGER = featureValue(FEATURES.GLIMMER_MODIFIER_MANAGER);
});