enifed('@ember/-internals/views/lib/views/states/has_element', ['exports', '@ember/polyfills', '@ember/-internals/views/lib/views/states/default', '@ember/runloop', '@ember/instrumentation'], function (exports, _polyfills, _default2, _runloop, _instrumentation) {
  'use strict';

  const hasElement = Object.create(_default2.default);

  (0, _polyfills.assign)(hasElement, {
    rerender(view) {
      view.renderer.rerender(view);
    },

    destroy(view) {
      view.renderer.remove(view);
    },

    // Handle events from `Ember.EventDispatcher`
    handleEvent(view, eventName, event) {
      if (view.has(eventName)) {
        // Handler should be able to re-dispatch events, so we don't
        // preventDefault or stopPropagation.
        return (0, _instrumentation.flaggedInstrument)(`interaction.${eventName}`, { event, view }, () => {
          return (0, _runloop.join)(view, view.trigger, eventName, event);
        });
      } else {
        return true; // continue event propagation
      }
    }
  });

  exports.default = hasElement;
});