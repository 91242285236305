enifed('@ember/-internals/runtime/lib/ext/rsvp', ['exports', 'rsvp', '@ember/runloop', '@ember/-internals/error-handling', '@ember/debug'], function (exports, _rsvp, _runloop, _errorHandling, _debug) {
  'use strict';

  exports.onerrorDefault = onerrorDefault;


  _rsvp.configure('async', (callback, promise) => {
    _runloop.backburner.schedule('actions', null, callback, promise);
  });

  _rsvp.configure('after', cb => {
    _runloop.backburner.schedule(_runloop._rsvpErrorQueue, null, cb);
  });

  _rsvp.on('error', onerrorDefault);

  function onerrorDefault(reason) {
    let error = errorFor(reason);
    if (error) {
      let overrideDispatch = (0, _errorHandling.getDispatchOverride)();
      if (overrideDispatch) {
        overrideDispatch(error);
      } else {
        throw error;
      }
    }
  }

  function errorFor(reason) {
    if (!reason) return;

    if (reason.errorThrown) {
      return unwrapErrorThrown(reason);
    }

    if (reason.name === 'UnrecognizedURLError') {
      true && !false && (0, _debug.assert)(`The URL '${reason.message}' did not match any routes in your application`, false);

      return;
    }

    if (reason.name === 'TransitionAborted') {
      return;
    }

    return reason;
  }

  function unwrapErrorThrown(reason) {
    let error = reason.errorThrown;
    if (typeof error === 'string') {
      error = new Error(error);
    }
    Object.defineProperty(error, '__reason_with_error_thrown__', {
      value: reason,
      enumerable: false
    });
    return error;
  }

  exports.default = _rsvp;
});