enifed('@ember/-internals/runtime/lib/mixins/-proxy', ['exports', '@glimmer/reference', '@ember/-internals/meta', '@ember/-internals/metal', '@ember/-internals/utils', '@ember/debug'], function (exports, _reference, _meta, _metal, _utils, _debug) {
  'use strict';

  exports.contentFor = contentFor;


  function contentPropertyDidChange(content, contentKey) {
    let key = contentKey.slice(8); // remove "content."
    if (key in this) {
      return;
    } // if shadowed in proxy
    (0, _metal.notifyPropertyChange)(this, key);
  } /**
    @module ember
    */

  function contentFor(proxy, m) {
    let content = (0, _metal.get)(proxy, 'content');
    let tag = (m === undefined ? (0, _meta.meta)(proxy) : m).readableTag();
    if (tag !== undefined) {
      tag.inner.second.inner.update((0, _metal.tagFor)(content));
    }
    return content;
  }

  /**
    `Ember.ProxyMixin` forwards all properties not defined by the proxy itself
    to a proxied `content` object.  See ObjectProxy for more details.
  
    @class ProxyMixin
    @namespace Ember
    @private
  */
  exports.default = _metal.Mixin.create({
    /**
      The object whose properties will be forwarded.
       @property content
      @type EmberObject
      @default null
      @private
    */
    content: null,

    init() {
      this._super(...arguments);
      (0, _utils.setProxy)(this);
      let m = (0, _meta.meta)(this);
      m.writableTag(() => (0, _reference.combine)([_reference.DirtyableTag.create(), _reference.UpdatableTag.create(_reference.CONSTANT_TAG)]));
    },

    willDestroy() {
      this.set('content', null);
      this._super(...arguments);
    },

    isTruthy: (0, _metal.computed)('content', function () {
      return !!(0, _metal.get)(this, 'content');
    }),

    willWatchProperty(key) {
      let contentKey = `content.${key}`;
      (0, _metal.addObserver)(this, contentKey, null, contentPropertyDidChange);
    },

    didUnwatchProperty(key) {
      let contentKey = `content.${key}`;
      (0, _metal.removeObserver)(this, contentKey, null, contentPropertyDidChange);
    },

    unknownProperty(key) {
      let content = contentFor(this);
      if (content) {
        return (0, _metal.get)(content, key);
      }
    },

    setUnknownProperty(key, value) {
      let m = (0, _meta.meta)(this);

      if (m.isInitializing() || m.isPrototypeMeta(this)) {
        // if marked as prototype or object is initializing then just
        // defineProperty rather than delegate
        (0, _metal.defineProperty)(this, key, null, value);
        return value;
      }

      let content = contentFor(this, m);

      true && !content && (0, _debug.assert)(`Cannot delegate set('${key}', ${value}) to the \'content\' property of object proxy ${this}: its 'content' is undefined.`, content);

      return (0, _metal.set)(content, key, value);
    }
  });
});