enifed("@ember/-internals/error-handling/index", ["exports"], function (exports) {
    "use strict";

    exports.getOnerror = getOnerror;
    exports.setOnerror = setOnerror;
    exports.getDispatchOverride = getDispatchOverride;
    exports.setDispatchOverride = setDispatchOverride;
    let onerror;
    const onErrorTarget = exports.onErrorTarget = {
        get onerror() {
            return onerror;
        }
    };
    // Ember.onerror getter
    function getOnerror() {
        return onerror;
    }
    // Ember.onerror setter
    function setOnerror(handler) {
        onerror = handler;
    }
    let dispatchOverride;
    // allows testing adapter to override dispatch
    function getDispatchOverride() {
        return dispatchOverride;
    }
    function setDispatchOverride(handler) {
        dispatchOverride = handler;
    }
});