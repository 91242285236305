enifed("@ember/string/lib/string_registry", ["exports"], function (exports) {
    "use strict";

    exports.setStrings = setStrings;
    exports.getStrings = getStrings;
    exports.getString = getString;
    // STATE within a module is frowned upon, this exists
    // to support Ember.STRINGS but shield ember internals from this legacy global
    // API.
    let STRINGS = {};
    function setStrings(strings) {
        STRINGS = strings;
    }
    function getStrings() {
        return STRINGS;
    }
    function getString(name) {
        return STRINGS[name];
    }
});