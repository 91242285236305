enifed("@ember/-internals/routing/lib/system/query_params", ["exports"], function (exports) {
    "use strict";

    class QueryParams {
        constructor(values = null) {
            this.isQueryParams = true;
            this.values = values;
        }
    }
    exports.default = QueryParams;
});