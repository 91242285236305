enifed('@ember/map/with-default', ['exports', '@ember/debug', '@ember/map/index', '@ember/map/lib/utils', '@ember/deprecated-features'], function (exports, _debug, _index, _utils, _deprecatedFeatures) {
  'use strict';

  let MapWithDefault;

  if (_deprecatedFeatures.MAP) {
    /**
    @class MapWithDefault
    @extends Map
    @private
    @constructor
    @param [options]
      @param {*} [options.defaultValue]
    */
    MapWithDefault = class MapWithDefault extends _index.default {
      constructor(options) {
        true && !false && (0, _debug.deprecate)('Use of @ember/MapWithDefault is deprecated. Please use native `Map` instead', false, {
          id: 'ember-map-deprecation',
          until: '3.5.0'
        });

        super();
        this.defaultValue = options.defaultValue;
      }

      /**
      @method create
      @static
      @param [options]
        @param {*} [options.defaultValue]
      @return {MapWithDefault|Map} If options are passed, returns
        `MapWithDefault` otherwise returns `EmberMap`
      @private
      @deprecated use native `Map` instead
      */
      static create(options) {
        if (options) {
          return new MapWithDefault(options);
        } else {
          return new _index.default();
        }
      }

      /**
      Retrieve the value associated with a given key.
       @method get
      @param {*} key
      @return {*} the value associated with the key, or the default value
      @private
      */
      get(key) {
        let hasValue = this.has(key);

        if (hasValue) {
          return super.get(key);
        } else {
          let defaultValue = this.defaultValue(key);
          this.set(key, defaultValue);
          return defaultValue;
        }
      }

      /**
      @method copy
      @return {MapWithDefault}
      @private
      */
      copy() {
        let Constructor = this.constructor;
        return (0, _utils.copyMap)(this, new Constructor({
          defaultValue: this.defaultValue
        }));
      }
    };
  }

  exports.default = MapWithDefault;
});