enifed("@ember/-internals/routing/lib/system/controller_for", ["exports"], function (exports) {
  "use strict";

  exports.default = controllerFor;
  /**
  @module ember
  */
  /**
    Finds a controller instance.
  
    @for Ember
    @method controllerFor
    @private
  */
  function controllerFor(container, controllerName, lookupOptions) {
    return container.lookup(`controller:${controllerName}`, lookupOptions);
  }
});