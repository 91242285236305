enifed('@ember/-internals/runtime/lib/mixins/target_action_support', ['exports', '@ember/-internals/environment', '@ember/-internals/metal', '@ember/debug', '@ember/deprecated-features'], function (exports, _environment, _metal, _debug, _deprecatedFeatures) {
  'use strict';

  exports.default = _metal.Mixin.create({
    target: null,
    targetObject: _deprecatedFeatures.TARGET_OBJECT ? (0, _metal.descriptor)({
      configurable: true,
      enumerable: false,
      get() {
        let message = `${this} Usage of \`targetObject\` is deprecated. Please use \`target\` instead.`;
        let options = { id: 'ember-runtime.using-targetObject', until: '3.5.0' };
        true && !false && (0, _debug.deprecate)(message, false, options);

        return this._targetObject;
      },
      set(value) {
        let message = `${this} Usage of \`targetObject\` is deprecated. Please use \`target\` instead.`;
        let options = { id: 'ember-runtime.using-targetObject', until: '3.5.0' };
        true && !false && (0, _debug.deprecate)(message, false, options);

        this._targetObject = value;
      }
    }) : undefined,
    action: null,
    actionContext: null,

    actionContextObject: (0, _metal.computed)('actionContext', function () {
      let actionContext = (0, _metal.get)(this, 'actionContext');

      if (typeof actionContext === 'string') {
        let value = (0, _metal.get)(this, actionContext);
        if (value === undefined) {
          value = (0, _metal.get)(_environment.context.lookup, actionContext);
        }
        return value;
      } else {
        return actionContext;
      }
    }),

    /**
    Send an `action` with an `actionContext` to a `target`. The action, actionContext
    and target will be retrieved from properties of the object. For example:
     ```javascript
    import { alias } from '@ember/object/computed';
     App.SaveButtonView = Ember.View.extend(Ember.TargetActionSupport, {
      target: alias('controller'),
      action: 'save',
      actionContext: alias('context'),
      click() {
        this.triggerAction(); // Sends the `save` action, along with the current context
                              // to the current controller
      }
    });
    ```
     The `target`, `action`, and `actionContext` can be provided as properties of
    an optional object argument to `triggerAction` as well.
     ```javascript
    App.SaveButtonView = Ember.View.extend(Ember.TargetActionSupport, {
      click() {
        this.triggerAction({
          action: 'save',
          target: this.get('controller'),
          actionContext: this.get('context')
        }); // Sends the `save` action, along with the current context
            // to the current controller
      }
    });
    ```
     The `actionContext` defaults to the object you are mixing `TargetActionSupport` into.
    But `target` and `action` must be specified either as properties or with the argument
    to `triggerAction`, or a combination:
     ```javascript
    import { alias } from '@ember/object/computed';
     App.SaveButtonView = Ember.View.extend(Ember.TargetActionSupport, {
      target: alias('controller'),
      click() {
        this.triggerAction({
          action: 'save'
        }); // Sends the `save` action, along with a reference to `this`,
            // to the current controller
      }
    });
    ```
     @method triggerAction
    @param opts {Object} (optional, with the optional keys action, target and/or actionContext)
    @return {Boolean} true if the action was sent successfully and did not return false
    @private
    */
    triggerAction(opts = {}) {
      let { action, target, actionContext } = opts;
      action = action || (0, _metal.get)(this, 'action');
      target = target || getTarget(this);

      if (actionContext === undefined) {
        actionContext = (0, _metal.get)(this, 'actionContextObject') || this;
      }

      if (target && action) {
        let ret;

        if (target.send) {
          ret = target.send(...[action].concat(actionContext));
        } else {
          true && !(typeof target[action] === 'function') && (0, _debug.assert)(`The action '${action}' did not exist on ${target}`, typeof target[action] === 'function');

          ret = target[action](...[].concat(actionContext));
        }

        if (ret !== false) {
          return true;
        }
      }

      return false;
    }
  });


  function getTarget(instance) {
    let target = (0, _metal.get)(instance, 'target');
    if (target) {
      if (typeof target === 'string') {
        let value = (0, _metal.get)(instance, target);
        if (value === undefined) {
          value = (0, _metal.get)(_environment.context.lookup, target);
        }

        return value;
      } else {
        return target;
      }
    }

    // if _targetObject use it
    if (_deprecatedFeatures.TARGET_OBJECT && instance._targetObject) {
      return instance._targetObject;
    }

    return null;
  }
});