enifed('@ember/-internals/extension-support/lib/container_debug_adapter', ['exports', '@ember/string', '@ember/-internals/runtime'], function (exports, _string, _runtime) {
  'use strict';

  exports.default = _runtime.Object.extend({
    /**
      The resolver instance of the application
      being debugged. This property will be injected
      on creation.
       @property resolver
      @default null
      @public
    */
    resolver: null,

    /**
      Returns true if it is possible to catalog a list of available
      classes in the resolver for a given type.
       @method canCatalogEntriesByType
      @param {String} type The type. e.g. "model", "controller", "route".
      @return {boolean} whether a list is available for this type.
      @public
    */
    canCatalogEntriesByType(type) {
      if (type === 'model' || type === 'template') {
        return false;
      }

      return true;
    },

    /**
      Returns the available classes a given type.
       @method catalogEntriesByType
      @param {String} type The type. e.g. "model", "controller", "route".
      @return {Array} An array of strings.
      @public
    */
    catalogEntriesByType(type) {
      let namespaces = (0, _runtime.A)(_runtime.Namespace.NAMESPACES);
      let types = (0, _runtime.A)();
      let typeSuffixRegex = new RegExp(`${(0, _string.classify)(type)}$`);

      namespaces.forEach(namespace => {
        for (let key in namespace) {
          if (!namespace.hasOwnProperty(key)) {
            continue;
          }
          if (typeSuffixRegex.test(key)) {
            let klass = namespace[key];
            if ((0, _runtime.typeOf)(klass) === 'class') {
              types.push((0, _string.dasherize)(key.replace(typeSuffixRegex, '')));
            }
          }
        }
      });
      return types;
    }
  });
});